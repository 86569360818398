<template>
  <div class="jc-component__range">
    
  </div>
</template>
<script>
import Vue from 'vue'
import axios from '../util/api'
import { local } from '../util/util'
export default {
    props: { },
    data(){
        return {
            code : '',
        }
    },
    mounted() {
      this.code =this.$route.query.code;
      this.login(this.code)
  },
  methods:{
       login (params) {
      axios.post('/pc/wx/login', { code: params}).then((v) => {
        local.set('token', v.data.token)
        axios.get('/pc/user/single').then((response) => {
          this.user = response.data.user;
          local.set('user', response.data.user);
          local.set('company_id', response.data.user.last_login_company_id);
          if (response.data.user.user_type == 3) {
            if (v.data.complete_state == '234') {
              this.isMenu = 1;
              local.set('isMenu', 1)
              this.$router.push({ name: 'Public' });

            } else if (v.data.complete_state == '235') {
              this.isMenu = 2;
              local.set('isMenu', 2)
              this.getCompany(String(response.data.user.last_login_company_id))
              this.getMenu(response.data.user.last_login_company_id)

            }
          } else if (response.data.user.user_type == 4) {
            this.isMenu = 2;
            local.set('isMenu', 2)
            this.getCompany1(String(response.data.user.last_login_company_id))
            this.getMenu(response.data.user.last_login_company_id)

          } else {
            this.isMenu = 2;
            local.set('isMenu', 2)
            this.getMenu(response.data.user.last_login_company_id)
          }

        })
      }).catch(v => {
         this.$message.error('网络异常');
      })
    },
    }
};
</script>
<style lang="scss" scoped>

</style>